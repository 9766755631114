import React from 'react';
import Helmet from "react-helmet";

import { ThemeContext } from '../../context/theme-context';

import Layout from '../../components/layout';
import Footer from '../../components/footer';
import Title from '../../components/title';
import BannerImage from '../../components/bannerImage';
import ContactBlock from '../../components/contactBlock';
import BannerResult from '../../components/bannerResult';
import IntroBlock from '../../components/introBlock';
import NextCase from '../../components/nextCase';
import SimplePhrase from '../../components/simplePhrase';
import ChromaticPalette from '../../components/chromaticPalette';

const CasesPage = ({ location }) => {

    return (
      <Layout bg="white" location={location}>
        <Helmet>
          <title>ATUO - Cases - Cervogia</title>
        </Helmet>

        <div className="container mx-auto">
          <Title
            title="Cervogia"
            titleColor="#FFA800"
            description="Uma marca rica em detalhes, cultura e tradição."
            smallDescription="[artesanal, única, nobre]"
          />

          <BannerImage
            src={`/cervogia/cervogia_content_one.jpg`}
            title="Cervogia"
          />

          <IntroBlock
            logo={`/cervogia/cervogia_logo.svg`}
            alt="Cervogia"
            description="Destacar-se no mercado de cervejas artesanais frente a grandes players. Ressaltar sua essência de marca premium e elementos nobres que compõem os produtos e geram experiências incomparáveis."
            list={[
              "Estratégia & Inovacão",
              "Pesquisa & Inteligência",
              "Design de Experiência do Consumidor",
            ]}
          />

          <SimplePhrase paddingX={20}>
            Um nome forte que reflete toda a nobreza e a tradição da cervejaria.
          </SimplePhrase>

          <div className="flex justify-center gap-x-16 md:px-24 items-center my-40 md:my-60 flex-col md:flex-row">
            <div className="md:w-1/2 md:px-16 text-2xl order-2 md:order-1 mt-24 md:mt-0 md:mt-0">
              <p>
                Cervogia significa muito além de um brinde. É a tradução do
                valor atribuído à cerveja nas regiões da velha Itália. <br />
                <br />O logo foi inspirado na riqueza dos ingredientes nobres,
                representados pela coroa. Já o nome carrega toda a sua tradição.
              </p>
            </div>

            <div className="md:w-1/2 order-1 md:order-2 text-center">
              <img
                src={`/cervogia/cervogia_stamp.svg`}
                alt="Cervogia"
                className="inline"
              />
            </div>
          </div>

          <ChromaticPalette
            description={
              "A paleta cromática apresenta o dourado representando a cevada, um cereal nobre utilizado na produção da cerveja. Já o marrom escuro carrega a representação do solo, rico em elementos essenciais para produzir os melhores insumos para as cervejas."
            }
          >
            <div className="py-24 md:py-16 flex flex-wrap gap-y-10 gap-x-9 md:gap-x-32 justify-evenly md:justify-center">
              <div
                className="cardColorBlock"
                data-aos="fade-up"
                data-aos-delay="0"
                data-aos-mirror="false"
                style={{ backgroundColor: "#ffa800" }}
              >
                #ffa800
              </div>
              <div
                className="cardColorBlock"
                data-aos="fade-up"
                data-aos-delay="300"
                data-aos-mirror="false" 
                style={{ backgroundColor: "#34140f" }}
              >
                #34140f
              </div>
            </div>
          </ChromaticPalette>

          <div className="text-left w-full md:w-3/5 md:text-center mx-auto my-40 md:my-60">
            <p className="text-2xl md:text-4xl font-semibold md:leading-normal">
              Posicionamento claro e identidade visual com os valores de uma
              marca única, que carrega sua essência em todos os detalhes.
            </p>
          </div>

          <div className="flex flex-col md:flex-row">
            <div className="w-full md:w-4/5 order-2 md:order-1">
              <p className="width-150 text-2xl md:text-4xl mb-10 font-semibold md:leading-normal">
                Rótulo clean, capaz de ressaltar a qualidade de suas safras,
                destacando a nobreza dos ingredientes da bebida
              </p>

              <p className="text-xl md:text-2xl mb-10">
                Visual robusto e imponente, que destaca o valor de seus
                ingredientes e seu posicionamento premium em qualquer
                prateleira. <br />
                <br />
                As letras representam o tipo da cerveja, e os número, a safra.
              </p>
            </div>

            <div className="order-1 md:order-2">
              <img src={`/cervogia/cervogia_content_two.png`} alt="Cervogia" />
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 items-center my-40 md:my-60 md:bg-black">
            <div>
              <img
                src={`/cervogia/cervogia_content_three.jpg`}
                alt="Cervogia"
              />
            </div>

            <div className="py-16 md:py-0">
              <p className="max-w-sm m-auto text-2xl md:text-4xl md:text-white">
                Para a Cervogia, cada safra é única, e isso mantém a tradição de
                sua produção artesanal
              </p>
            </div>
          </div>
        </div>

        <BannerResult
          bg="#FFA800"
          list={[
            "Posicionamento claro",
            "Premium em sua região",
            "Maior valorização dos ingredientes",
            "Maior destaque entre as cervejas premium",
          ]}
        />

        <NextCase
          link="/cases/trelo"
          title="Trêlo"
          description="Inspiração, charme e elegância em uma marca que conquistou o Brasil."
          image="/trelo-next.png"
        />

        <ThemeContext.Consumer>
          {(cx) => (
            <ContactBlock
              description="Gostou dos nossos cases? Tem muito mais de onde veio! Clique no botão ao lado e entre em contato conosco. Vamos dar o primeiro passo para sua marca ir além."
              weather={cx?.cookies?.weather}
            />
          )}
        </ThemeContext.Consumer>

        <Footer bg="white" />
      </Layout>
    );
};

export default CasesPage;
